.small {
    font-height: 0.85em;
    font-weight: 600
}
.projectSummary {
    margin-top: 18px;
    line-height: 1.6em;
    font-size: 1.1rem;
    font-weight: 600;
}
.eeurope-logo {
    max-width: 140px;
    top: -2px;
    position: relative;
    max-height: 20px;
  }
#contact {
	min-height: 800px;
}